import React, { useEffect, useState } from 'react';
import { Socket } from 'phoenix';
import { getToken } from '../../../utils/Utils';
import { WS_API_HOST } from '../../../shared/constants/Constants';

// npm i phoenix@1.4.10
const token = getToken()
const socket = new Socket(WS_API_HOST, {});

function WebSocketComponent({ uid }) {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Configuración de la conexión del socket
    socket.connect({ uid: uid, token: token });

    // Unirse al canal con el número de móvil
    const channel = socket.channel(`chat:${uid}`, {});

    channel.join()
      .receive('ignore', () => console.error('Auth error'))
      .receive('ok', () => console.log('Joined successfully'));

    // Manejo de eventos
    channel.onError((e) => console.error('Oops', e));
    channel.onClose((e) => console.error('Closed', e));

    // channel.push("test:msg", { data: "mensaje de prueba" });

    channel.on('user:entered', (data) => {
      console.log('User entered:', data.chat_history);
    });

    channel.on('new:msg', (payload) => {
        debugger
        console.log('Mensaje recibido:', payload);

    });


    // Función para enviar mensajes
    const sendMessage = (message) => {
      channel.push('new:msg', { phone: mobile, message: message });
    };

    // Limpieza al desmontar el componente
    return () => {
      channel.leave();
      socket.disconnect();
    };
  }, [uid, token]);

  return (
    <div>
      <h1>WebSocket Chat</h1>
      <ul>
        {messages.map((msg, index) => (
          <li key={index}>
            <strong>{msg.origin}:</strong> {msg.message} <em>({msg.at})</em>
          </li>
        ))}
      </ul>
      {/* Puedes agregar un formulario de entrada para enviar mensajes si lo deseas */}
    </div>
  );
}

export default WebSocketComponent;
