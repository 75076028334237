import { useNotify } from "@fredybustos/usenotify"

export default function useNotification() {
  const { notifySuccess, notifyError, notifyWarn, notifyInfo } = useNotify()

  const TYPES = {
    success: notifySuccess,
    error: notifyError,
    warn: notifyWarn,
    info: notifyInfo
  }

  const notify = ({ content, type = 'success' } = {}) => {
    TYPES[type](content, {
      bgcolor: '#91cae5',
      color: '#000'
    })
  }
  return { notify }
}
