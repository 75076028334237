import React, { useState } from 'react';
import { FolderIcon, TrashIcon, PlusIcon, Share } from '../../components/icons';
import Spinner from '../../components/spinner';
import './styles.css';
import Translate from '../../utils/Translate';
import useAddProjectStore from '../../hooks/store/useAddProjectStore';
import useBranchyStore from '../../hooks/store/useBranchyStore';
import useDelete from './useDelete';
import useShare from '../components/modal/share/useShare';

export default function Projects({ onCloseModal, showTrial }) {
  const [uidSelected, setUidSelected] = useState('');
  const [uidUpdate, setUidUpdate] = useState('');

  const { onOpenModal } = useShare()
  const { onDeleteProject, loading: submiting } = useDelete();
  const {
    projectsDeleted,
    projects,
    switchProject,
    getListProjects,
  } = useBranchyStore()

  const {
    onChangeStatus,
    onOpenDialog,
    creating,
    isUpdating
  } = useAddProjectStore();

  const handleRedirect = (project) => {
    switchProject(project)
    onCloseModal();
  };

  const handleDeleteProject = (uid) => {
    setUidSelected(uid);
    onDeleteProject({ uid });
    setTimeout(() => {
      onCloseModal();
    }, 200)
  };

  const handleUpdateProject = (uid) => {
    setUidUpdate(uid);
    onChangeStatus({ uid, callback: getListProjects });
  };

  const handleOpenModal = (project) => {
    onOpenModal(project)
    switchProject(project)
  }

  return (
    <div className="support-container">
      <MyProjects
        projects={projects}
        submiting={submiting}
        uidSelected={uidSelected}
        onOpenModal={handleOpenModal}
        onRedirect={handleRedirect}
        onDeleteProject={handleDeleteProject}
      />
      {projectsDeleted.length > 0 && (
        <RecentDeleted
          uidUpdate={uidUpdate}
          isUpdating={isUpdating}
          projects={projectsDeleted}
          onChangeStatus={handleUpdateProject}
        />
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          type="submit"
          onClick={onOpenDialog}
          disabled={creating || showTrial}
          className="generate-ideas-button"
          style={{ textAlign: 'center', opacity: showTrial ? '0.8': 1 }}
        >
          {creating
            ? <Spinner style={{ margin: '0 auto', borderLeftColor: '#000' }} />
            : Translate.get('newProject', 'New Project')
          }
        </button>
      </div>
    </div>
  );
}


function MyProjects({
  projects = [],
  onRedirect,
  submiting,
  uidSelected,
  onOpenModal,
  onDeleteProject,
}) {
  const canDelete = projects.length > 1;
  return (
    <div className="mb-4">
      <h3 className="support-title">{Translate.get('yourProjects', 'Your Projects')}</h3>
      {projects.map(project => (
        <div key={project.id} className="support-projects mb-2">
          <div
            role="button"
            className="support-project-title"
            onClick={() => onRedirect(project)}
          >
            <FolderIcon disabled={!canDelete} className='text-red-400' />
            <h4>{project.description}</h4>
          </div>
          <div className="support-actions">
            {submiting && project.uid === uidSelected
              ? <Spinner />
              : <TrashIcon
                disabled={!canDelete}
                className={!canDelete && 'disabled:opacity-65 disabled:cursor-default'}
                onClick={canDelete ? () => onDeleteProject(project.uid) : undefined}
              />
            }
            <div className='w-[2px] h-4 bg-[#D6DD3D] !mx-2' />
            <Share onClick={() => onOpenModal(project)} />
          </div>
        </div>
      ))}
    </div>
  );
}

function RecentDeleted({
  projects = [], onChangeStatus, isUpdating, uidUpdate,
}) {
  return (
    <div>
      <h3 className="support-title">{Translate.get('recentDeleted', 'Recently deleted')}</h3>
      {projects.map(project => (
        <div key={project.id} className="support-projects mb-2">
          <div
            role="button"
            className="support-project-title"
          >
            <FolderIcon />
            <h4>{project.description}</h4>
          </div>
          {isUpdating && project.uid === uidUpdate
            ? <Spinner />
            : <PlusIcon onClick={() => onChangeStatus(project.uid)} />
          }
        </div>
      ))}
    </div>
  );
}
