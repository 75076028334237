import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import "./SignInEmail.css";
import ApiUser from '../../shared/login/ApiUser';
import User from '../../models/UserStore'
import Translate from '../../utils/Translate';
import CheckErrors from '../../components/alerts/CheckErrors'
import mixpanel from 'mixpanel-browser';
import { InviteCollaborators } from "../../services/seenapse";
import Storage from 'localStorage';
import { isEmpty, parseQuery } from "../../utils/Utils";

const projectShareStore = Storage.getItem('projectshare') || '{}';
const parseProjectShare = JSON.parse(projectShareStore);

class SignInEmail extends Component {
  state = {
    email: "",
    password: "",
    description: "",
    showAlertErrors: false,
    errors: null
  };

  componentDidUpdate() {
    const { child } = this.props;
    if (child && !this.state.name) {
      const { name, description } = child;
      this.setState({
        name,
        description
      });
      this.props.updateForm({ name, description });
    }
  }

  updateField = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  closeModalErrors = () => {
    this.setState({ showAlertErrors: false })
  }

  collaboratorsInvite = (projectshare) => {
    InviteCollaborators({
      email: projectshare.email,
      projectUid: projectshare.projectUid
    })
      .then(() => {
        if (!isEmpty(parseProjectShare)) {
          Storage.remove('projectshare');
        }
        this.props.history.push({
          pathname: '/ai/project/chat',
          search: `?projectUid=${projectshare.projectUid}&project=shared`
        })
      })
      .catch((error) => {
        console.error(error)
        window.location.href = "/";
      })
  }

  onSumit = (e) => {
    let errors = []
    e.preventDefault();
    const { email, password } = this.state;

    const { search } = this.props.location
    const searchParams = parseQuery(search)
    const parseProjectShareInfo = searchParams?.projectUid ? {
      email: searchParams.email || '',
      projectUid: searchParams.projectUid || ''
    } : parseProjectShare

    ApiUser.login(email, password )
      .then((response) => {
        mixpanel.track('Sign up', {'Method': 'Email'});
        const userLang = navigator.language || navigator.userLanguage;
        const lang = userLang.split("-")[0] === 'es' ? 'es' : 'en';
        User.saveSession(response.data.jwt, response.data.user_id, lang)

        if (Boolean(parseProjectShareInfo?.projectUid) ) {
          return this.collaboratorsInvite(parseProjectShareInfo)
        }

        window.location.href = "/";
      }).catch((error) => {
        this.setState({
          errors: [`${Translate.get('errLogin','Username or password are invalid')}`],
          showAlertErrors: true
        })
      });
  }

  render() {
    const { showAlertErrors, errors } = this.state
    return <div>
      <CheckErrors
        open={showAlertErrors}
        errors={errors}
        onOk={this.closeModalErrors}
      />
      <div className="header-spacer"></div>
        <div className="login-email-form">
          <form id="formLogin" className="content content-form">
            <fieldset>
              <div className="field">
              <label for="">
                <span className="theme-blue">
                  ></span> Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={e => this.updateField("email", e.target.value)} required
                />
              </div>
              <div className="field">
              <label for="">
                <span className="theme-orange">
                  > </span>{Translate.get('password', 'Password')}
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  required
                  onChange={e => this.updateField("password", e.target.value)}
                />
              </div>
            </fieldset>
            <div className="action">
              <a
                className="forgot"
                href="/password/request"
              >
                {Translate.get('forgotYourPassword', 'FORGOT YOUR PASSWORD?')}
              </a>
              <button
                id="login"
                className="right"
                onClick={this.onSumit}
              >
                {Translate.get('logIn', 'Log In')}
              </button>
            </div>
          </form>
        </div>
    </div>
  }
}

export default inject("modalStore")(observer(withRouter(SignInEmail)));
