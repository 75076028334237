import { useEffect, useState } from "react";
import useNavigation from "../../../hooks/useNavigation";
import { GetBranchyCollaborators } from "../../../services/seenapse";

export default function useCollaborators() {
  const { searchParams } = useNavigation();
  const [collaborators, setCollaborators] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchParams.projectUid) {
      getCollaborators(searchParams.projectUid);
    }
  }, [searchParams.projectUid]);

  const getCollaborators = (projectUid) => {
    setLoading(true);
    GetBranchyCollaborators(projectUid)
      .then((response) => {
        setCollaborators(response.data)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => setLoading(false))
  };

  return { loading, collaborators, getCollaborators }
}
