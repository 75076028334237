import { useEffect, useState } from 'react';
import useNavigation from "../../../hooks/useNavigation";
import { ValidateUserEmail } from "../../../services/seenapse";
import { getUid } from '../../../utils/Utils';
import useInviteCollaborators from './useInviteCollaborators';
import useNotification from '../../../hooks/useNotification';


const userUid = getUid();

export default function useSharedValidation() {
  const [isLoading, setIsLoading] = useState(false);
  const { params, onNavigate } = useNavigation();
  const { notify } = useNotification()
  const {isLoading: submiting, inviteCollaborators } = useInviteCollaborators();

  useEffect(() => {
    if (params.email) {
      validateUserEmail()
    }
  }, [params.email])

  const validateUserEmail = () => {
    setIsLoading(true);
    ValidateUserEmail(params.email)
      .then((data) => {
        if (data.exist && userUid) {
          return inviteCollaborators()
        }

        if(data.exist && !userUid) {
          return onNavigate('/login/email', {
            searchParams: {
              email: params.email,
              projectUid: params.project_uid
            }
          })
        }

        onNavigate('/register', {
          searchParams: {
            email: params.email,
            projectUid: params.project_uid
          }
        })
      })
      .catch((error) => {
        console.error(error)
        notify({ content: 'Something went wrong', type: 'error' })
      })
      .finally(() => setIsLoading(false))
  }

  return { isLoading: isLoading || submiting, validateUserEmail }
}
