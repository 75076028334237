import React, { Fragment, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn, parseJwt } from '../utils/Utils';
import Storage from 'localStorage'
import Header from '../components/header/Header';
import ProjectDialog from '../app/projects/addProject';
import useAddProjectstore from '../hooks/store/useAddProjectStore';
import useUser from '../hooks/useUser';
import useShare from '../app/components/modal/share/useShare';
import ShareModal from '../app/components/modal/share';

const token = Storage.getItem('token') || '';

export default function ProtectedRoute({
  component: Component,
  ...rest
}) {
  const { isOpenDialog, onCloseDialog } = useAddProjectstore()
  const { project, isOpenModal, onCloseModal } = useShare()

  const { user, showTrial } = useUser()

  const hasUser = isLoggedIn()

  useEffect(() => {
    if (user && token) {
      const parseToken = token && parseJwt(token);
      if (!user.is_logged_in && parseToken && parseToken.type === 'sso') {
        window.location = '/logout';
      }
    }
  }, [user]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (showTrial && props.location.pathname !== '/subscription') {
          return <Redirect to='/subscription' />
        }

        if (hasUser) {
          return <Fragment>
            <Header
              history={props.history}
              user={user}
            />
            <Component {...props} />
            {isOpenDialog &&
              <ProjectDialog
                isOpen={isOpenDialog}
                onClose={onCloseDialog}
              />
            }
            {isOpenModal &&
              <ShareModal
                isOpenDialog={isOpenModal}
                onCloseModal={onCloseModal}
                projectName={project.description}
              />
            }
          </Fragment>
        }

        return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      }}
    />
  )
}
