import React from 'react'
import useCollaborators from './useCollaborators'
import Translate from '../../../utils/Translate'
import DotsLoader from '../../components/dots'
import avatarDefault from '../../../assests/img/avatar-default.png';
import { TrashIcon } from '../../../components/icons';
import useRemove from './useRemove';
import Spinner from '../../../components/spinner';
import useNavigation from '../../../hooks/useNavigation';

export default function Collaborators() {
  const { searchParams } = useNavigation();
  const { collaborators, loading, getCollaborators } = useCollaborators()
  const { loading: submiting, uidSelected, removeCollaborator } = useRemove()

  if (loading) {
    return <DotsLoader size={40} />
  }

  const hasCollaborators = collaborators.length > 0

  return hasCollaborators && (
    <div className='text-left'>
      <h1 className='text-base mt-3 mb-3'>{Translate.get('collaboratorsTitle', 'Guest contributors')}</h1>
      {collaborators.length > 0 && collaborators.map((collaborator) => {
        const userAvatar = !collaborator?.avatar?.includes('https') ? avatarDefault : collaborator.avatar;
        return (
          <div
            key={collaborator.user_id}
            className='flex mb-2 border-b pb-2 last:border-b-0 border-b-[#efeff40d]'
          >
            <figure className='w-5 h-5'>
              <img src={userAvatar} alt='coll-avatar' />
            </figure>
            <div className='ml-2 flex justify-between w-full'>
              <p className='text-[14px]'>{collaborator.email}</p>
              <div>
                {submiting && collaborator.user_uid === uidSelected
                  ? <Spinner />
                  : <TrashIcon
                    disabled={submiting}
                    className='disabled:opacity-65 disabled:cursor-default'
                    onClick={() => removeCollaborator({
                      projectUid: searchParams.projectUid,
                      userUid: collaborator.user_id,
                      callback: () => getCollaborators(searchParams.projectUid)
                    })}
                  />
                }
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
