import { useState } from 'react';
import { InviteCollaborators } from '../../../services/seenapse'
import useNavigation from '../../../hooks/useNavigation';
import useNotification from '../../../hooks/useNotification';

export default function useInviteCollaborators() {
  const [isLoading, setIsLoading] = useState(false);
  const { params, onNavigate } = useNavigation();
  const { notify } = useNotification()

  const onRedirect = () => {
    onNavigate('/ai/project/chat', {
      searchParams: { projectUid: params.project_uid }
    })
  }

  const inviteCollaborators = () => {
    setIsLoading(true);
    InviteCollaborators({
      email: params.email,
      projectUid: params.project_uid
    })
    .then(() => {
      notify({ content: 'Collaborator invited successfully', type: 'success' })
      onRedirect()
    })
    .catch((error) => {
      console.error(error.response.data.errors.project_id)
      const message = `Project: ${error.response.data.errors.project_id}` || 'Something went wrong'
      notify({ content: message, type: 'error' })
      setTimeout(() => {
        onNavigate('/app')
      }, 300)
    })
    .finally(() => setIsLoading(false))
  }

  return { isLoading, inviteCollaborators }
}
