import React from 'react';
import useSharedValidation from './useSharedValidation';
import DotsLoader from '../../components/dots';

export default function ShareValidation() {
  const { isLoading } = useSharedValidation();
  return (
    <div className='flex items-center justify-center py-10 flex-col h-[80vh]'>
      <p className='text-xl mb-10'>Please wait a moment.</p>
      {isLoading && <DotsLoader />}
    </div>
  )
};
