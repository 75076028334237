import { hookstate, useHookstate } from '@hookstate/core';
import { parseProxyData } from '../../../../utils/Utils';
import { ShareBranchy } from '../../../../services/seenapse';
import useNavigation from '../../../../hooks/useNavigation';
import * as Yup from "yup"
import useNotification from '../../../../hooks/useNotification';

export const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email required'),
})


const initialState = hookstate({
  isOpenModal: false,
  isLoding: false,
  project: {}
});

export default function useShare() {
  const { isOpenModal, project, isLoding } = useHookstate(initialState);
  const { notify } = useNotification();

  const { searchParams } = useNavigation()

  const onOpenModal = (sharedProject) => {
    project.set(sharedProject)
    isOpenModal.set(true)
  };

  const onCloseModal = () => isOpenModal.set(false);

  const onChangeLoading = (value) => isLoding.set(value);

  const onSubmitProject = (values) => {
    onChangeLoading(true);
    ShareBranchy({
      projectUid: searchParams.projectUid,
      email: values.email.trim()
    })
      .then(() => {
        notify({ content: 'Project shared successfully', type: 'success' })
        onCloseModal();
        onChangeLoading(false);
      })
      .catch((error) => {
        console.log('Error:', error);
        notify({ content: 'Error sharing project', type: 'error' })
      })
      .finally(() => {
        onChangeLoading(false);
      })
  };

  return {
    isOpenModal: parseProxyData(isOpenModal.get()),
    isLoding: parseProxyData(isLoding.get()),
    project: parseProxyData(project.get()),
    onOpenModal,
    onCloseModal,
    onSubmitProject
  }
}
