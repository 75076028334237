import { useState } from "react";
import { RemoveCollaborator } from "../../../services/seenapse";
import useShare from "../../components/modal/share/useShare";
import useNotification from "../../../hooks/useNotification";

export default function useRemove() {
  const [loading, setLoading] = useState(false);
  const [uidSelected, setUidSelected] = useState(null)

  const { notify } = useNotification();
  const { onCloseModal } = useShare()

  const removeCollaborator = ({ projectUid, userUid, callback }) => {
    setLoading(true);
    setUidSelected(userUid)
    RemoveCollaborator({ projectUid, userUid })
      .then((response) => {
        notify({ content: response.data, type: 'success' })
        onCloseModal()
        callback?.()
      })
      .catch((error) => {
        console.error(error)
        notify({ content: 'Somethig went wrong', type: 'error' })
      })
      .finally(() => {
        setLoading(false)
        setUidSelected(null)
      })
  };
  return { loading, uidSelected, removeCollaborator }
}
