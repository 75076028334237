import React from 'react';
import ChatComponent from './components/ChatComponent';
import useBranchyStore from '../../hooks/store/useBranchyStore';

// Main App component
export default function Branchy() {
  const { projectName } = useBranchyStore();

  return (
    <div className="App bg-body-bg min-h-screen flex flex-col">
      <div
        className="flex items-center bg-black rounded-lg p-2 cursor-pointer"
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
      >
        <h1 className="text-white font-thin text-3xl">{projectName}</h1>
      </div>
      <ChatComponent />
    </div>
  );
}
