import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';
import PasswordChange from '../app/signin/PasswordChange';
import PasswordRequest from '../app/signin/PasswordRequest';
import RegisterSocial from '../app/signin/RegisterSocial';
import Register from '../app/signin/Register';
import Logout from '../app/logout';
import SignInEmail from '../app/signin/SignInEmail';

import AccountContainer from '../containers/account/AccountContainer';
import LoginOauth from '../shared/login/oauth/loginOauth';
import Info from '../containers/infoSeenapse/info';
import SearchPage from '../containers/SearchPage';
import NotFound from '../content/components/not-found';
import UpdateProfile from '../components/updateProfile/Profile';
import Stores from '../stores/Stores';
import EditSeenapse from '../containers/editSeenapse/editSeenapse';
import NewSeenapse from '../containers/newSeenapse/newSeenapse';
import { TotalNotificationsProvider } from '../stores/Notificationstore';
import BeforeGenerateIdeas from '../components/detail/BeforeGeneratingIdeas';
import BeforeGeneratingPage from '../containers/BeforeGeneratingPage';
import ClimateSolutions from '../app/climate';
import ClimateThanksMessage from '../app/climate/ClimateThanks';
import BranchyChat from '../app/branchy';
import PublicRoute from './PublicRoutes';
import ProtectedRoute from './ProtectedRoutes';
import Landing from '../app/landing';
import App from '../app';
import Subscription from '../app/subscription';
import ShareValidation from '../app/branchy/shareValidation';
import WebSocketComponent from '../app/branchy/components/WebSocketComponent';
import Storage from 'localStorage';


const Routes = () => (
  <Provider {...Stores}>
    <TotalNotificationsProvider>
      <Switch>
        <PublicRoute exact restricted={true}  path="/" component={Landing} />
        <PublicRoute restricted={true} path="/login/email" component={SignInEmail} />
        <PublicRoute restricted={true} path="/register/social" component={RegisterSocial} />
        <PublicRoute restricted={true} path="/register" component={Register} />
        <PublicRoute restricted={true} path="/password/request" component={PasswordRequest} />
        <PublicRoute restricted={true} path="/password/change/:token" component={PasswordChange} />
        <PublicRoute restricted={true} path="/login/:type/:userId/:admin/:token" component={LoginOauth} />
        <PublicRoute path="/project/:project_uid/shared/:email/accept" component={ShareValidation} />
        <PublicRoute path="/logout" component={Logout} />

        {/* Only for testing websocket funcionality
          <ProtectedRoute path="/chat1231231"
            component={() => <WebSocketComponent uid="fd8aaa7a-fd3b-4218-8111-29c110e0c89a" />}
          />
        Only for texting websocket funcionality */}

        <ProtectedRoute exact path="/app" component={App} />
        <ProtectedRoute path="/subscription" component={Subscription} />
        <ProtectedRoute path="/update-profile" component={UpdateProfile} />
        <ProtectedRoute path="/climate" component={ClimateSolutions} />
        <ProtectedRoute path="/climate/thanks" component={ClimateThanksMessage} />
        <ProtectedRoute path="/ai/project/chat" component={BranchyChat} />
        <ProtectedRoute path="/before-generating-ideas" component={BeforeGenerateIdeas} />
        <ProtectedRoute path="/:parent/add" component={NewSeenapse} />
        <ProtectedRoute path="/:parent/:child/edit" component={EditSeenapse} />
        <ProtectedRoute path="/:module/:userId/:section" component={AccountContainer} />

        <Route path="/search/:query" component={SearchPage} />
        <Route path="/search/:type/:query" component={SearchPage} />
        <Route path="/search/:type/:query/:parent/:child" component={SearchPage} />
        <Route path="/before-generating-ideas/:parent/:child" component={BeforeGeneratingPage} />
        <Route path="/search/:type/:query/:parent/:child/new" component={SearchPage} />
        <Route path="/:info" component={Info} />

        <Route component={NotFound} />
      </Switch>
    </TotalNotificationsProvider>
  </Provider>
);

export default Routes;
